import React, { useEffect, useMemo, useState } from 'react';
import './CampaignContacts.scss';
import { useParams } from 'react-router-dom';
import { defaultListObj } from '../../utils/const';
import { getCampaignContacts, getCampaignPrivate, getCampaignSales, getMetricCampaignInfluencer } from '../../api';
import _ from 'lodash';
import { CampaignDetailHeaderSection, STATISTICS_MODE } from '../dashboard/CampaignStatisticPage';
import { normalizePrice } from '../../utils';
import Table from '../../components/layout/table/Table';
import { contactsFields, salesFields } from '../../utils/entityFields';
import { Col } from '../../components/ui';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { DateTime } from 'luxon';
import TablePagination from '../../components/layout/table/TablePagination';

const CampaignSales = () => {
  let { cmpId } = useParams();
  const [contact, setContact] = useState(defaultListObj);
  const [campaign, setCampaign] = useState({});
  const { t } = useTranslation();
  const hasData = _.size(contact.data) > 0;
  const [page, setPage] = useState(0);

  const onRequest = async (pageRequest) => {
    if (!cmpId) {
      return;
    }
    const [dataRes, campRes] = await Promise.all([getCampaignContacts(cmpId, 0), getCampaignPrivate(cmpId)]);
    console.debug({ dataRes, campRes });
    setContact(dataRes);
    setCampaign(campRes);
  };

  const handleChangePage = async (newPage) => {
    const res = await getCampaignContacts(cmpId, newPage);
    setContact(res);
    setPage(newPage);
  };

  const bodyCellComponent = useMemo(() => {
    return {
      infNickname: (row) => `@${_.get(row, 'infNickname', ' - ')}`,
      phone: (row) => `+${_.get(row, 'phone.prefix', ' - ')} ${_.get(row, 'phone.number', ' - ')}`,
      date: (row) => DateTime.fromMillis(_.get(row, 'time', 0)).toFormat('dd/MM/yyyy HH:mm'),
    };
  }, []);

  useEffect(() => {
    onRequest(0);
  }, []);

  return (
    <div id="campaign-sales">
      <CampaignDetailHeaderSection campaign={campaign} />
      <Col md={12} sm={12} xs={12} style={{ marginBottom: '2rem', marginTop: '1rem' }}>
        <Typography variant="h3" color="primary">
          {t('campaign contacts')}
        </Typography>
        {!hasData && (
          <Col md={12} sm={12} xs={12}>
            {' '}
            <span>{t('noSalesYet')}</span>{' '}
          </Col>
        )}
      </Col>
      {hasData && <Table entityFields={contactsFields} data={contact.data} bodyCellComponent={bodyCellComponent} showCheckbox={false} >
        <TablePagination rows={contact.data} setPage={handleChangePage} totalData={contact.total} page={page} rowsPerPagePass={30} />
      </Table>}
    </div>
  );
};

export default CampaignSales;
